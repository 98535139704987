import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./Components/TopBar/Topbar";
import Sidebars from "./Components/Sidebars/Sidebars";
import DashCard from "./Components/Topcards/addDashCards";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import TopCards from './Components/Topcards/Topcards';
import AdminLogin from './Components/Login/Login';
import KycRequests from "./Components/KycRequest/KycRequest";
import User from "./Components/Users/Users";
import KYCDetails from "./Components/KycRequest/kycDetails"
import Stocklist from "./Components/allStocks/allStocks";
import AdminUsers from "./Components/AdminUsers/AdminUsers";
import ActiveStock from "./Components/allStocks/ActiveStock";
import { WebSocketProvider } from './Context/WebSocketContext';
import WithdrawRequests from "./Components/WithdrawRequest/WithdrawRequest";
import WithdrawRequestDetails from "./Components/WithdrawRequest/WithdrawDetails";
import TransactionRequests from "./Components/TransactionRequests/TransactionRequests";
import UserDetails from "./Components/Users/UserDetails";
import Dashboard from "./Components/Dashboard/Dashboard";
import OptionChain from "./Components/allStocks/OptionChain";

function App() {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  
  return (
    <div  style={{ display: 'flex', height: '100vh' }}>
      {location.pathname === "/" ? (
        <AdminLogin /> 
      ) : (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Sidebars />
            <Box
              sx={{
                flex: 1,  
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden', 
              }}
            >
              <Topbar />
              <Box sx={{flex: 1, overflowY: 'auto', padding: '20px',}}>
                <WebSocketProvider>
                <Routes>
                  <Route path="/Stocklist" element={<Stocklist />} />
                  <Route path="/Topcards" element={<TopCards />} />
                  <Route path="/KycRequest" element={<KycRequests />} />
                  <Route path="/Users" element={<User />} />
                  <Route path="/kyc-details/:kycId" element={<KYCDetails />} />
                  <Route path="/addDashCard" element={<DashCard />} />
                  <Route path="/AdminUser" element={<AdminUsers />} />
                  <Route path="/ActiveStock" element={<ActiveStock />} />
                  <Route path="/WithdrawRequests" element={<WithdrawRequests />} />
                  <Route path="/withdraw-details/:withdrawId" element={<WithdrawRequestDetails />} />
                  <Route path="/WalletApproval" element={<TransactionRequests />} />
                  <Route path="/UserDetails/:userId" element={<UserDetails />} />
                  <Route path="/Dashboard" element={<Dashboard />} />
                  <Route path="/OptionChain" element={<OptionChain />} />
                </Routes>
                </WebSocketProvider>
              </Box>
            </Box>
          </ThemeProvider>
        </ColorModeContext.Provider>
      )}
    </div>
  );
}

export default App;
