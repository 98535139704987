import React, { useEffect, useState } from "react";
import { Box, InputBase, IconButton, Button, Skeleton, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import config from '../../Config';
import Cookies from 'js-cookie';

const OptionChain = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const saveInstrumentData = async () => {
      const loadingSwal = Swal.fire({
        title: 'Saving Option Data...',
        text: 'Please wait while we save the instrument data.',
        icon: 'info',
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const accessToken = Cookies.get('accessToken');
        const apiUrl = `${config.apiBaseUrl}/admin/saveFNO`;

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization-Token": accessToken,
          },
        });

        if (response.status === 200) {
          const result = await response.json();

          await Swal.fire({
            title: 'Success!',
            text: result.message || 'Instrument data saved successfully.',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#3085d6',
          });

          const secondLoadingSwal = Swal.fire({
            title: 'Saving Database...',
            text: 'Please wait while we save the database data.',
            icon: 'info',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          const secondApiUrl = `${config.apiBaseUrl}/admin/saveDbFNO`;
          const secondResponse = await fetch(secondApiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization-Token": accessToken, 
            },
          });

          secondLoadingSwal.close();

          if (secondResponse.status === 200) {
            const secondResult = await secondResponse.json();
            Swal.fire({
              title: 'DataBase Updated !',
              text: secondResult.message || 'Database successfully saved.',
              icon: 'success',
              confirmButtonText: 'OK',
              confirmButtonColor: '#3085d6',
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: 'Failed to save database data. Please try again.',
              icon: 'error',
            });
          }

        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Failed to save instrument data. Please try again.',
            icon: 'error',
          });
          loadingSwal.close();
        }

      } catch (error) {
        console.error("Error saving instrument data:", error);
        Swal.fire({
          title: 'Error!',
          text: 'An error occurred while saving the data.',
          icon: 'error',
        });
        loadingSwal.close(); 
      }
    };



  const updateInstrumentData = async () => {
    Swal.fire({
      title: 'Updating Option Data...',
      text: 'Please wait while the update request is being processed.',
      icon: 'info',
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const accessToken = Cookies.get('accessToken')
      console.log(accessToken);
      const apiUrl = `${config.apiBaseUrl}/admin/updateOptionDB`;
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "access-token": accessToken,
        },
      });

      if (response.status === 200) {
        const result = await response.json();
        Swal.fire({
          title: 'Update Successful!',
          text: result.message || 'Instrument data updated successfully.',
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update instrument data. Please try again.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error updating instrument data:", error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while updating the data.',
        icon: 'error',
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem('adminToken');
      if (!token) {
        console.error('No admin token found. Please log in.');
        return;
      }

      const apiUrl = `${config.apiBaseUrl}/admin/getAllOption`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log(data);
      
      // Mapping the response to fit the required table data structure
      const formattedData = data.optionList.map((item) => ({
        id: item.instrument_token,
        instrument_token: item.instrument_token,
        tradingsymbol: item.tradingsymbol,
        name: item.name,
        expiry: item.expiry,
        exchange: item.exchange,
      }));

      setRows(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredRows = rows.filter((row) =>
    row.tradingsymbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
    row.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
    row.expiry.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    { field: "instrument_token", headerName: "Instrument Token", flex: 1 },
    { field: "tradingsymbol", headerName: "Trading Symbol", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "expiry", headerName: "Expiry", flex: 1 },
    { field: "exchange", headerName: "Exchange", flex: 1 },
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  const isSuperAdmin = sessionStorage.getItem("role") === "Super_Admin";

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
        <Header title="OPTION CHAIN" subtitle="List Of Option Chain For Managing." />
        <Box display="flex" gap="10px">
          {isSuperAdmin && (
            <>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "200px" }}
                onClick={saveInstrumentData}
              >
                Save FNO
              </Button>

              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "200px" }}
                onClick={updateInstrumentData}
              >
                Update Option DB
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-start" width="100%" mb="10px">
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px" sx={{ width: "300px" }}>
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
            marginBottom: "20px",
          },
        }}
      >
        {loading ? renderShimmer() : <DataGrid rows={filteredRows} columns={columns} components={{ Toolbar: GridToolbar }} />}
      </Box>
    </Box>
  );
};

export default OptionChain;
