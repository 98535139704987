import React, { useState, useEffect } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; 
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PaymentIcon from '@mui/icons-material/Payment'; 
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DashboardIcon from '@mui/icons-material/Dashboard';  
import AssessmentIcon from '@mui/icons-material/Assessment';

const Item = ({ title, to, icon, selected, setSelected, onClick, nested }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isActive = selected === title;
  const textColor = isActive ? "#6870fa" : (theme.palette.mode === 'light' ? 'black' : colors.grey[100]);

  return (
    <MenuItem
      component={<Link to={to} />}
      active={isActive}
      style={{
        color: textColor,
        padding: "10px 20px",
        paddingLeft: nested ? "40px" : "20px", 
      }}
      onClick={onClick || (() => setSelected(title))}
      icon={icon}
    >
      <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '12px', color: textColor }}>
        {title}
      </Typography>
    </MenuItem>
  );
};

const MySidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  const [selected, setSelected] = useState(""); 
  const [role, setRole] = useState('');  
  const [isStockManagementOpen, setIsStockManagementOpen] = useState(true); 
  const [isUserManagementOpen, setIsUserManagementOpen] = useState(true);
  const location = useLocation();  
  const navigate = useNavigate();  

  const titleColor = theme.palette.mode === 'light' ? 'black' : 'white';
  const iconColor = theme.palette.mode === 'light' ? 'black' : 'white';

  useEffect(() => {
    if (location.pathname === "/Stocklist") {
      setSelected("Stock List");
    } else if (location.pathname === "/Topcards") {
      setSelected("Top Card");
    } else if (location.pathname === "/KycRequest") {
      setSelected("KYC Request");
    } else if (location.pathname === "/WithdrawRequests") {
      setSelected("Withdraw Request");
    } else if (location.pathname === "/Users") {
      setSelected("Users");
    } else if (location.pathname === "/ActiveStock") {
      setSelected("Active Stock");
    } else if (location.pathname === "/WalletApproval") {
      setSelected("Wallet Approval");
    } else if (location.pathname === "/Dashboard") {
      setSelected("Dashboard"); 
    } else if (location.pathname === "/AdminUser") {
      setSelected("AdminUser");
    } else if (location.pathname === "/OptionChain") { 
      setSelected("Option Chain");
    } else {
      setSelected(""); 
    }
  }, [location.pathname]);



  useEffect(() => {
    const storedRole = sessionStorage.getItem('role'); 
    if (storedRole) {
      setRole(storedRole); 
    }
  }, []);  

  const handleLogout = () => {
    sessionStorage.clear(); 
    document.cookie.split(";").forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;  
    });

    setTimeout(() => {
      navigate("/"); 
    }, 100); 
  };

  return (
    <Box
      sx={{
        height: '100vh',
        "& .ps-sidebar-container": {
          background: `${colors.primary[400]} !important`,
          height: '100%',
        },
        "& .ps-menu-button": {
          backgroundColor: "transparent !important",
        },
        "& .ps-menu-button:hover": {
          color: "#868dfb !important",
        },
        "& .ps-active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed} style={{ height: '100%' }}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon style={{ color: iconColor }} /> : undefined}
            style={{
              margin: "2px 0",
              color: colors.grey[100],
              transition: "0.5s",
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h4" color={titleColor}></Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon style={{ color: iconColor }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="20px" display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {'Unknown'}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {role || 'User'}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "5%"}>
          <Item
            title="Dashboard"
            to="/Dashboard"
            icon={<DashboardIcon />}
            selected={selected}
            setSelected={setSelected}
          />

          <MenuItem
            style={{
              color: colors.grey[100],
              padding: "10px 20px",
              fontWeight: "bold",
            }}
            onClick={() => setIsStockManagementOpen(!isStockManagementOpen)}
            icon={<ContactsOutlinedIcon />}
          >
            <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '12px' }}>
              Stock Management
            </Typography>
          </MenuItem>

          {isStockManagementOpen && (
            <>
              <Item
                title="Stock List"
                to="/Stocklist"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
              <Item
                title="Active Stock"
                to="/ActiveStock"
                icon={<CheckCircleIcon />}
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
              <Item
                title="Option Chain" 
                to="/OptionChain"  
                icon={<AssessmentIcon />}  
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
              <Item
                title="Top Card"
                to="/Topcards"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
            </>
          )}

          <MenuItem
            style={{
              color: colors.grey[100],
              padding: "10px 20px",
              fontWeight: "bold",
            }}
            onClick={() => setIsUserManagementOpen(!isUserManagementOpen)}
            icon={<PersonAddAltIcon />}
          >
            <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '12px' }}>
              User Management
            </Typography>
          </MenuItem>

          {isUserManagementOpen && (
            <>
              <Item
                title="KYC Request"
                to="/KycRequest"
                icon={<VerifiedUserIcon />}
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
              
              <Item
                title="Wallet Approval "
                to="/WalletApproval"
                icon={<AccountBalanceWalletIcon />}
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
              {role === "Super_Admin" && (
                <Item
                  title="Withdraw Request"
                  to="/WithdrawRequests"
                  icon={<PaymentIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  nested={true}
                />
              )}
              <Item
                title="Users"
                to="/Users"
                icon={<PeopleIcon />}
                selected={selected}
                setSelected={setSelected}
                nested={true}
              />
            </>
          )}

          {role === "Super_Admin" && (
          <Item
            title="Admin User"
            to="/AdminUser"
            icon={<AdminPanelSettingsIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          )}
          <Item
            title="Logout"
            to="#"
            icon={<ExitToAppIcon />}
            onClick={handleLogout}
          />
        </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default MySidebar;
