import React, { useEffect, useState } from "react";
import { Box, InputBase, Snackbar, Alert, Button as MuiButton, Skeleton, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import config from '../../Config';

const TransactionRequests = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false); 

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const token = sessionStorage.getItem("adminToken");

        if (!token) {
          setSnackbarMessage("No admin token found. Please log in.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }

        const apiUrl = `${config.apiBaseUrl}/admin/getAddBalanceRequests`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (data.requestList) {
          const formattedData = data.requestList.map((item) => ({
            id: item._id,
            userId: item.userId,
            transactionId: item.transactionId,
            referenceId: item.referenceId || "N/A",
            status: item.status,
            amount : item.amount,
          }));
          setRows(formattedData);
        } else {
          setSnackbarMessage("No transaction data found.");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setSnackbarMessage("Error fetching transaction data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, []);

  const filteredRows = rows.filter((row) => {
    const transactionId = row.transactionId ? row.transactionId.toLowerCase() : '';
    const userId = row.userId ? row.userId.toLowerCase() : '';
    
    return (
      transactionId.includes(searchQuery.toLowerCase()) ||
      userId.includes(searchQuery.toLowerCase())
    );
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const approveTransaction = async (transactionId, userId, referenceId) => {
    if (isLoadingTransaction) return; 
    setIsLoadingTransaction(true); 

    try {
      const token = sessionStorage.getItem("adminToken");

      if (!token) {
        setSnackbarMessage("No admin token found. Please log in.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const apiUrl = `${config.apiBaseUrl}/admin/approveAddBalance`;
      const requestBody = { transactionId, userId, referenceId };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.status === 200) {
        setSnackbarMessage(data.message || `Transaction ${transactionId} has been approved.`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setRows((prevRows) =>
          prevRows.map((row) =>
            row.transactionId === transactionId ? { ...row, status: "completed" } : row
          )
        );
      } else if (response.status === 400) {
        setSnackbarMessage("Transaction has already been processed.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(data.message || "Failed to approve transaction.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error approving transaction:", error);
      setSnackbarMessage("Error approving transaction.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoadingTransaction(false); 
    }
  };

  const rejectTransaction = async (transactionId, userId) => {
    if (isLoadingTransaction) return; 
    setIsLoadingTransaction(true); 

    try {
      const token = sessionStorage.getItem("adminToken");

      if (!token) {
        setSnackbarMessage("No admin token found. Please log in.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      const apiUrl = `${config.apiBaseUrl}/admin/rejectBalanceRequest`;
      const requestBody = { transactionId, userId };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.status === 200) {
        setSnackbarMessage(data.message || `Transaction ${transactionId} has been rejected.`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setRows((prevRows) =>
          prevRows.map((row) =>
            row.transactionId === transactionId ? { ...row, status: "rejected" } : row
          )
        );
      } else if (response.status === 400) {
        setSnackbarMessage("Transaction is already processed and cannot be rejected.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(data.response || "Failed to reject transaction.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error rejecting transaction:", error);
      setSnackbarMessage("Error rejecting transaction.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoadingTransaction(false); 
    }
  };

  const columns = [
    {
      field: "transactionId",
      headerName: "Transaction ID",
      flex: 1,
    },
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
    },
    {
      field: "referenceId",
      headerName: "Reference ID",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.status === "pending"
                ? "orange"
                : params.row.status === "completed"
                ? "green"
                : "red",
          }}
        >
          {params.row.status}
        </span>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => (
        <span>{parseFloat(params.row.amount).toFixed(2)}</span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <MuiButton
            variant="contained"
            color="success"
            onClick={() => approveTransaction(params.row.transactionId, params.row.userId, params.row.referenceId)}
            disabled={params.row.status === "completed" || isLoadingTransaction} 
            startIcon={isLoadingTransaction ? <Skeleton variant="circle" width={20} height={20} /> : <CheckCircleIcon />}
          >
            {isLoadingTransaction ? "Loading..." : "Approve"}
          </MuiButton>
          <MuiButton
            variant="contained"
            color="error"
            onClick={() => rejectTransaction(params.row.transactionId, params.row.userId)}
            disabled={params.row.status === "completed" || isLoadingTransaction} 
            startIcon={isLoadingTransaction ? <Skeleton variant="circle" width={20} height={20} /> : <CancelIcon />}
            sx={{ ml: 1 }}
          >
            {isLoadingTransaction ? "Loading..." : "Reject"}
          </MuiButton>
        </>
      ),
      width: 250,
    }
  ];

  const renderShimmer = () => (
    <Box>
      {Array.from(new Array(5)).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={50} sx={{ margin: "10px 0" }} />
      ))}
    </Box>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="WALLET APPROVAL" subtitle="Manage Wallet Requests" />
        <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="3px">
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search by Transaction ID or User ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        mb="20px"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        {loading ? (
          renderShimmer()
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 15]}
          />
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TransactionRequests;
