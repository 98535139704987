import React, { useState, useEffect } from "react";
import { Box, Button as MuiButton, Snackbar, Alert, Typography, Card, CardContent, TextField, Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import PersonIcon from "@mui/icons-material/Person";
import { green, red, grey, yellow } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";  
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; 
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import config from '../../Config';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#fff",
  borderRadius: "16px",
  boxShadow: theme.palette.mode === "dark" ? "0 6px 15px rgba(0, 0, 0, 0.2)" : "0 10px 20px rgba(0, 0, 0, 0.1)",
  padding: "24px",
  marginBottom: "20px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#333" : "#ccc"}`,
  display: "flex", 
  flexDirection: "column",
  height: "100%", 
  overflow: "hidden",
}));

const PortfolioCard = styled(StyledCard)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.paper : "#f9fafb", 
  border: `1px solid ${theme.palette.mode === "dark" ? grey[700] : "#e1e1e1"}`, 
  boxShadow: theme.palette.mode === "dark" ? "0 4px 12px rgba(0, 0, 0, 0.2)" : "0 4px 12px rgba(0, 0, 0, 0.1)",
  position: "relative", 
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "15px",
  marginBottom: "20px",
}));

const UserDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [userData, setUserData] = useState({
    marginSetting: "", 
    isActive: true,
  });
  const [walletData, setWalletData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [portfolioData, setPortfolioData] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("adminToken");

    if (token && userId) {
      const apiUrl = `${config.apiBaseUrl}/admin/getUserById`;
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "x-access-token": token,
          "user-id": userId,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch user data");
          }
        })
        .then((data) => {
          if (data && data.walletData) {
            setWalletData(data.walletData);
            setUserData({
              ...userData,
              ...data.userData, 
              marginSetting: data.userData.marginSetting || "", 
              isActive: data.userData.isActive ,
            });
            setPortfolioData(data.portfolioData || []); 
          } else {
            setSnackbarMessage("Failed to fetch wallet data");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        })
        .catch((error) => {
          setSnackbarMessage("Error fetching wallet data");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage("Missing token or user-id in session");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [userId]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    const token = sessionStorage.getItem("adminToken");
    const marginValue = userData.marginSetting;

    if (!marginValue) {
      setSnackbarMessage("Margin value cannot be empty!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (token && userId) {
      const apiUrl = `${config.apiBaseUrl}/admin/setMarginLimit`;
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({
          userId,
          margin: marginValue,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to update margin");
          }
        })
        .then((data) => {
          setSnackbarMessage("Margin successfully updated!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage("Error updating margin");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage("Missing token or user-id in session");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleMarginChange = (e) => {
    setUserData({
      ...userData,
      marginSetting: e.target.value,
    });
  };

  const toggleUserStatus = () => {
    const token = sessionStorage.getItem("adminToken");
    if (token && userId) {
      const apiUrl = `${config.apiBaseUrl}/admin/changeUserStatus`;
      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
        body: JSON.stringify({
          userId,
          status: !userData.isActive,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to change user status");
          }
        })
        .then((data) => {
          setUserData({ ...userData, isActive: !userData.isActive });
          setSnackbarMessage("User status updated!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage("Error changing user status");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarMessage("Missing token or user-id in session");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="flex-start" mb="20px">
        <IconButton 
          onClick={() => navigate(-1)} 
          sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} 
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="USER DETAILS" subtitle="Manage User Information" />
        <MuiButton 
          variant="contained" 
          color={userData.isActive ? "error" : "success"} 
          onClick={toggleUserStatus}
        >
          {userData.isActive ? "Disable User" : "Activate User"}
        </MuiButton>
      </Box>

      <Grid container spacing={3}>
        {/* Wallet & Margin Card */}
        <Grid item xs={12} md={6}>
          <StyledCard sx={{ height: "100%" }}>
            <CardContent>
              {walletData ? (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body1" color={colors.grey[200]}>
                        <strong>Total Balance:</strong> {walletData.total_balance}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" color={colors.grey[200]}>
                        <strong>Withdrawable Balance:</strong> {walletData.withdraw_balance}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Typography variant="body1" color={colors.grey[200]} />
              )}

              <Box mt={2}>
                <TextField
                  label="Set Margin"
                  type="number"
                  value={userData.marginSetting}
                  onChange={handleMarginChange}
                  fullWidth
                  variant="outlined"
                  sx={{ marginBottom: "20px" }}
                />
                <MuiButton variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                  Submit
                </MuiButton>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledCard sx={{ height: "100%", borderRadius: "16px", padding: "20px", boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)" }}>
            <CardContent>
              {/* Profile Section */}
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px", gap: "20px" }}>
                <Box
                  sx={{
                    width: "90px",
                    height: "90px",
                    borderRadius: "50%",
                    backgroundColor: colors.grey[300],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
                    marginRight: "16px",
                  }}
                >
                  <PersonIcon sx={{ fontSize: "48px", color: colors.primary[500] }} />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" color={colors.grey[100]} fontWeight="bold">
                    {userData.first_name} {userData.last_name}
                  </Typography>
                  <Typography variant="body2" color={colors.grey[300]}>
                    {userData.userId}
                  </Typography>
                </Box>

                {/* Status Icon */}
                <Box>
                  {userData.isActive ? (
                    <CheckCircleIcon sx={{ color: green[500], fontSize: "30px" }} />
                  ) : (
                    <CancelIcon sx={{ color: red[500], fontSize: "30px" }} />
                  )}
                </Box>
              </Box>

              {/* Contact & KYC Info */}
              <Box sx={{ marginTop: "16px" }}>
                {/* Email */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
                  <Typography variant="body1" color={colors.grey[200]} sx={{ fontWeight: "bold" }}>
                    <strong>Email:</strong>
                  </Typography>
                  <Typography variant="body2" color={colors.grey[300]}>
                    {userData.email}
                  </Typography>
                </Box>

                {/* Username */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
                  <Typography variant="body1" color={colors.grey[200]} sx={{ fontWeight: "bold" }}>
                    <strong>Username:</strong>
                  </Typography>
                  <Typography variant="body2" color={colors.grey[300]}>
                    {userData.user_name}
                  </Typography>
                </Box>

                {/* Aadhar Card */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
                  <Typography variant="body1" color={colors.grey[200]} sx={{ fontWeight: "bold" }}>
                    <strong>Aadhar Card:</strong>
                  </Typography>
                  <Typography variant="body2" color={colors.grey[300]}>
                    {userData.kycData?.aadharCard?.number || "N/A"}
                  </Typography>
                </Box>

                {/* PAN Card */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
                  <Typography variant="body1" color={colors.grey[200]} sx={{ fontWeight: "bold" }}>
                    <strong>PAN Card:</strong>
                  </Typography>
                  <Typography variant="body2" color={colors.grey[300]}>
                    {userData.kycData?.panCard?.number || "N/A"}
                  </Typography>
                </Box>

                {/* KYC Status */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "12px" }}>
                  <Typography variant="body1" color={colors.grey[200]} sx={{ fontWeight: "bold" }}>
                    <strong>KYC Status:</strong>
                  </Typography>

                  <Typography 
                    variant="body2" 
                    color={userData.kycData?.kycStatus === "Approved" ? green[500] : userData.kycData?.kycStatus === "Rejected" ? red[500] : yellow[500]} 
                    sx={{ fontWeight: "bold" }}
                  >
                    {userData.kycData?.kycStatus || "Pending"}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Portfolio Card */}
        <Grid item xs={12}>
          <PortfolioCard sx={{ width: "100%" }}>
            <CardContent sx={{ position: "relative", height: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="h6" color={colors.grey[200]} fontWeight="bold">
                Portfolio Details
              </Typography>
              {portfolioData && portfolioData.length > 0 ? (
                portfolioData.map((instrument) => (
                  <Box key={instrument._id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="body1" color={colors.grey[200]}>
                      <strong>Instrument:</strong> {instrument.symbol} ({instrument.stockType})
                    </Typography>
                    <Typography variant="body1" color={colors.grey[200]}>
                      <strong>Quantity:</strong> {instrument.quantity}
                    </Typography>
                    <Typography variant="body1" color={colors.grey[200]}>
                      <strong>Avg. Price:</strong> {instrument.avgPrice.toFixed(2)}
                    </Typography>
                    <Typography variant="body1" color={colors.grey[200]}>
                      <strong>Trade Type:</strong> {instrument.tradeType}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" color={colors.grey[200]}>
                  No portfolio data available.
                </Typography>
              )}
            </CardContent>
          </PortfolioCard>
        </Grid>
      </Grid>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDetails;
