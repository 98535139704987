import React, { useState, useEffect } from "react";
import { Box, Button as MuiButton, Snackbar, Alert, Typography, Card, CardContent, Divider, Chip, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../Headers/Header";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { green, red, yellow } from "@mui/material/colors";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";  
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1a1a1a" : "#fff",
  borderRadius: "16px",
  boxShadow: theme.palette.mode === "dark" ? "0 6px 15px rgba(0, 0, 0, 0.2)" : "0 10px 20px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  marginBottom: "20px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#333" : "#ccc"}`,
}));

const InfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "15px",
  marginBottom: "20px",
}));

const StyledButton = styled(MuiButton)(({ theme, colorType }) => ({
  borderRadius: "50px",
  textTransform: "capitalize",
  padding: "10px 30px",
  fontWeight: "bold",
  boxShadow: "0px 6px 10px rgba(0,0,0,0.2)",
  backgroundColor:
    colorType === "approve" ? green[500] :
    colorType === "reject" ? red[500] : yellow[500],
  "&:hover": {
    backgroundColor:
      colorType === "approve" ? green[700] :
      colorType === "reject" ? red[700] : yellow[700],
  },
  color: "#fff",
  display: "flex",
  alignItems: "center",
}));

const BankDetailsCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#2a2a2a" : "#fafafa",
  borderRadius: "16px",
  boxShadow: theme.palette.mode === "dark" ? "0 6px 15px rgba(0, 0, 0, 0.2)" : "0 10px 15px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  marginBottom: "20px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#444" : "#ddd"}`,
}));

const BankDetailsHeader = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  color: theme.palette.mode === "dark" ? green[400] : green[600],
  marginBottom: "16px",
}));

const BankInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const BankLogo = styled("img")({
  width: "60px", 
  height: "60px", 
  objectFit: "contain",
  borderRadius: "8px",
  marginRight: "15px",
});

const WithdrawRequestDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { withdrawId } = useParams(); 
  const [withdrawRequestData, setWithdrawRequestData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchWithdrawRequestDetails = async () => {
    const token = sessionStorage.getItem('adminToken');
      try {
        const response = await fetch(`https://backend.smartcapital.co.in/admin/getWithdrawRequestById`, {
          method: "GET",
          headers: {
            "x-access-token": token, 
            "request-id": withdrawId,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch withdrawal request details.");
        }

        const data = await response.json();
        setWithdrawRequestData(data);
      } catch (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error.message || "Failed to fetch withdrawal request details.");
        setSnackbarOpen(true);
      } finally {
        setLoading(false); 
      }
    };

    fetchWithdrawRequestDetails();
  }, [withdrawId]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleAction = async (action) => {
    try {
      let status = '';
      let message = '';
      let promptMessage = '';
      let inputLabel = '';
      let body = {};

      if (action === "approve") {
        promptMessage = 'Please enter the Reference ID:';
        inputLabel = 'Reference ID';
      } else if (action === "reject") {
        promptMessage = 'Please enter the reason for rejection:';
        inputLabel = 'Rejection Reason';
      }

      const { value } = await Swal.fire({
        title: action === "approve" ? "Approve Withdrawal" : "Reject Withdrawal",
        text: promptMessage,
        input: 'text',
        inputPlaceholder: inputLabel,
        showCancelButton: true,
        confirmButtonText: 'Submit',
      });

      if (value) {
        if (action === "approve") {
          status = "approved";
          message = `Withdrawal request approved successfully with Reference ID: ${value}!`;
          body = {
            requestId: withdrawId, 
            status: "approved",
            referenceId: value,
          };
        } else if (action === "reject") {
          status = "rejected";
          message = `Withdrawal request rejected. Reason: ${value}`;
          body = {
            requestId: withdrawId, 
            status: "rejected",
            comment: value,
          };
        }

        const token = sessionStorage.getItem('adminToken');
        const response = await fetch(`https://backend.smartcapital.co.in/admin/updateWithdrawRequest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token, 
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          throw new Error("Failed to update withdrawal request.");
        }

        setSnackbarSeverity("success");
        setSnackbarMessage(message);
        setSnackbarOpen(true);

        if (withdrawRequestData) {
          setWithdrawRequestData({
            ...withdrawRequestData,
            status,
          });
        }
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message || "Failed to update withdrawal request.");
      setSnackbarOpen(true);
    }
  };

  if (!withdrawRequestData) {
    return (
      <Box m="20px">
        <Typography variant="h5" color={colors.grey[500]}>
          No withdrawal request data found.
        </Typography>
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="flex-start" mb="20px">
        <IconButton 
          onClick={() => navigate(-1)} 
          sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} 
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Header title="WITHDRAW REQUEST DETAILS" subtitle="Manage Withdrawal Requests" />
      </Box>

      {/* User Information */}
      <StyledCard>
        <CardContent>
          <InfoContainer>
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                backgroundColor: colors.grey[300],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <PersonIcon sx={{ fontSize: "40px", color: colors.primary[500] }} />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography variant="h6" color={colors.grey[100]} fontWeight="bold">
                  {withdrawRequestData.user.first_name} {withdrawRequestData.user.last_name}
                </Typography>
                <Typography variant="body1" color={colors.grey[300]}>
                  {withdrawRequestData.user.userId}
                </Typography>
              </Box>

              <Typography variant="body1" color={colors.grey[300]} sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label={withdrawRequestData.status}
                  sx={{
                    backgroundColor:
                      withdrawRequestData.status === "approved" ? green[500] :
                      withdrawRequestData.status === "rejected" ? red[500] : yellow[500],
                    color: "#000",
                    borderRadius: "16px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    padding: "4px 12px",
                    marginTop: "8px",
                  }}
                />
              </Typography>
            </Box>
          </InfoContainer>

          <Divider sx={{ margin: "20px 0", borderColor: colors.grey[700] }} />

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EmailIcon sx={{ marginRight: "5px" }} />
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Email:</strong> {withdrawRequestData.user.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </StyledCard>

      {withdrawRequestData?.bank ? (
        <BankDetailsCard>
          <BankDetailsHeader>Bank Details</BankDetailsHeader>
          <BankInfo>
            <InfoContainer>
              {withdrawRequestData.bank.image && (
                <BankLogo src={withdrawRequestData.bank.image} alt="Bank Logo" />
              )}
              <Box>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Bank:</strong> {withdrawRequestData.bank.bank_name}
                </Typography>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Account Name:</strong> {withdrawRequestData.bank.acc_holder}
                </Typography>
                <Typography variant="body1" color={colors.grey[200]}>
                  <strong>Account Number:</strong> {withdrawRequestData.bank.acc_number}
                </Typography>
              </Box>
            </InfoContainer>
          </BankInfo>
        </BankDetailsCard>
      ) : (
        <Typography variant="body1" color={colors.grey[500]}>
          No bank details available.
        </Typography>
      )}

      {withdrawRequestData.status !== "approved" && withdrawRequestData.status !== "rejected" && (
        <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <StyledButton colorType="approve" onClick={() => handleAction("approve")}>
            <CheckCircleIcon sx={{ marginRight: "8px" }} />
            Approve
          </StyledButton>
          <StyledButton colorType="reject" onClick={() => handleAction("reject")}>
            <CancelIcon sx={{ marginRight: "8px" }} />
            Reject
          </StyledButton>
        </Box>
      )}


      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WithdrawRequestDetails;
