import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [socketData, setSocketData] = useState({});
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  
  const api_key = 'oe0zgbgzlbuqdmra';
  
  const adminToken = sessionStorage.getItem('adminToken');
  const adminId = sessionStorage.getItem('adminId');

  // console.log(adminId);
  
  const wsUrl = `wss://real-time.smartcapital.co.in?api_key=${api_key}&x-access-token=${adminToken}&user-id=${adminId}`;
  
  const connectWebSocket = () => {
    if (adminToken && adminId) {
      const webSocket = new WebSocket(wsUrl);
      setWs(webSocket);

      webSocket.onopen = () => {
        console.log('WebSocket connection established');
        setIsConnected(true);
      };

      webSocket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.message) {
          const accessToken = data.accessToken;
          if (accessToken) {
            Cookies.set('accessToken', accessToken);
            // console.log(accessToken);
          }
        }

        // if (Array.isArray(data) && data.length > 0) {
        //   data.forEach(stockUpdate => {
        //     const { instrument_token, last_price, change, ohlc } = stockUpdate;

        //     setSocketData(prevSocketData => ({
        //       ...prevSocketData,
        //       [instrument_token]: {
        //         last_price,
        //         net_change: change,
        //         ohlc,
        //       },
        //     }));
        //   });
        // }
      };

      webSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
        setTimeout(connectWebSocket, 5000);
      };

      webSocket.onclose = () => {
        console.log('WebSocket connection closed');
        setIsConnected(false);
        setTimeout(connectWebSocket, 5000); 
      };
    } else {
      console.log('No adminToken or adminId found in sessionStorage');
    }
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
        console.log('WebSocket connection closed explicitly');
      }
    };
  }, []); 

  const closeWebSocket = () => {
    if (ws) {
      ws.close();
      setIsConnected(false);
      console.log('WebSocket connection closed explicitly');
    }
  };

  return (
    <WebSocketContext.Provider value={{ socketData, isConnected, closeWebSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketData = () => {
  return useContext(WebSocketContext);
};
